:root {
  --font-family-lato: "Lato", sans-serif;
  --font-size-small: .75rem;
  --font-size-normal: 1.4rem;
  --font-size-huge: 2.25rem;
  --color-primary: #fff;
  --color-secondary: #000;
  --color-delicate: #878787;
  --body-padding: 6vw;
  --logo-width: 2.25rem;
  --heading-margin-top: 3.5rem;
  --paragraph-max-width: 750px;
  --paragraph-margin-top: 2rem;
  --paragraph-line-height: 1.85rem;
}

* {
  --webkit-tap-highlight-color: transparent;
  outline: none;
}

html, body {
  min-height: 100%;
}

html {
  font-size: calc(70% + .35vw);
}

body {
  padding: var(--body-padding);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-normal);
  background-color: var(--color-primary);
  color: var(--color-secondary);
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

a {
  display: inline-block;
}

a img {
  width: var(--logo-width);
  border: 0;
  display: block;
}

h1 {
  font-size: var(--font-size-huge);
  margin: var(--heading-margin-top) 0 0 -1px;
  padding: 0;
  font-weight: bold;
}

p {
  max-width: var(--paragraph-max-width);
  margin: var(--paragraph-margin-top) 0 0 0;
  font-weight: lighter;
  line-height: var(--paragraph-line-height);
  width: 100%;
}

p a {
  color: var(--color-secondary);
  font-weight: normal;
  text-decoration: none;
}

p a:hover {
  border-color: var(--color-secondary);
}

.version {
  font-size: var(--font-size-small);
  color: var(--color-delicate);
  padding: .5rem;
  position: fixed;
  bottom: 0;
  right: 0;
}
/*# sourceMappingURL=index.f497334c.css.map */
