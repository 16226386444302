:root {
  --font-family-lato: 'Lato', sans-serif;
  --font-size-small: 0.75rem;
  --font-size-normal: 1.4rem;
  --font-size-huge: 2.25rem;
  --color-primary: #ffffff;
  --color-secondary: #000000;
  --color-delicate: #878787;
  --body-padding: 6vw;
  --logo-width: 2.25rem;
  --heading-margin-top: 3.5rem;
  --paragraph-max-width: 750px;
  --paragraph-margin-top: 2rem;
  --paragraph-line-height: 1.85rem;
}

* {
  --webkit-tap-highlight-color: transparent;
  outline: none;
}

html,
body {
  min-height: 100%;
}

html {
  font-size: calc(70% + 0.35vw);
}

body {
  margin: 0;
  padding: var(--body-padding);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-normal);
  background-color: var(--color-primary);
  color: var(--color-secondary);
  -webkit-font-smoothing: antialiased;
}

a {
  display: inline-block;

  img {
    display: block;
    width: var(--logo-width);
    border: 0;
  }
}

h1 {
  font-size: var(--font-size-huge);
  margin: var(--heading-margin-top) 0 0 -1px;
  padding: 0;
  font-weight: bold;
}

p {
  width: 100%;
  max-width: var(--paragraph-max-width);
  margin: var(--paragraph-margin-top) 0 0 0;
  font-weight: lighter;
  line-height: var(--paragraph-line-height);

  a {
    color: var(--color-secondary);
    font-weight: normal;
    text-decoration: none;

    &:hover {
      border-color: var(--color-secondary);
    }
  }
}

.version {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: var(--font-size-small);
  padding: 0.5rem;
  color: var(--color-delicate);
}
